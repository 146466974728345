import request from "@/core/services/axios";

export function studentlisttotalanswersheetExport(params) {
  // 导出总分和单科的接口地址
  return request({
    url: `/report/unionoverallanaly/studentlisttotalanswersheet/export`,
    method: "post",
    data: params,
  });
}
export function overallanaly(params) {
  // 总体分析-总体情况分析
  return request({
    url: `/report/unionoverallanaly/overallanaly`,
    method: "get",
    params: params,
  });
}
export function studentlisttotallevel(params) {
  // 总体分析-总分-等级成绩榜
  return request({
    url: `/report/unionoverallanaly/studentlisttotallevel`,
    method: "post",
    data: params,
  });
}
export function studentlistsubjectlevel(params) {
  // 总体分析-单科-等级成绩榜
  return request({
    url: `/report/unionoverallanaly/studentlistsubjectlevel`,
    method: "post",
    data: params,
  });
}
export function gradelevellist(params) {
  // 学校分析-年级等级榜
  return request({
    url: `/report/unionschoolanaly/gradelevellist`,
    method: "post",
    data: params,
  });
}

export function areaoverallanaly(params) {
  // 总体分析-各地区总体情况分析
  return request({
    url: `/report/unionoverallanaly/areaoverallanaly`,
    method: "get",
    params: params,
  });
}
export function totalscoresegmentdistribution(params) {
  // 总体分析-总分分数段分布-总分分数段分布表
  return request({
    url: `/report/unionoverallanaly/totalscoresegmentdistribution`,
    method: "get",
    params: params,
  });
}
export function totalscoresegmentdistributionpic(params) {
  // 总体分析-总分分数段分布-总分分数段分布图
  return request({
    url: `/report/unionoverallanaly/totalscoresegmentdistributionpic`,
    method: "get",
    params: params,
  });
}
export function totalscoresegmentdistributiondetails(params) {
  // 总体分析-总分分数段分布-总分详细分数段分布表
  return request({
    url: `/report/unionoverallanaly/totalscoresegmentdistributiondetails`,
    method: "get",
    params: params,
  });
}
export function subjectscoresegmentdistribution(params) {
  // 总体分析-单科分数段分布-单科分数段分布表
  return request({
    url: `/report/unionoverallanaly/subjectscoresegmentdistribution`,
    method: "get",
    params: params,
  });
}
export function subjectscoresegmentdistributionpic(params) {
  // 总体分析-单科分数段分布-单科分数段分布图
  return request({
    url: `/report/unionoverallanaly/subjectscoresegmentdistributionpic`,
    method: "get",
    params: params,
  });
}
export function subjectscoresegmentdistributiondetails(params) {
  // 总体分析-单科分数段分布-单科详细分数段分布表
  return request({
    url: `/report/unionoverallanaly/subjectscoresegmentdistributiondetails`,
    method: "get",
    params: params,
  });
}
export function subjectfrequencydistributionnormaltestpic(params) {
  // 总体分析-单科分数段分布-频率分布正态检验图
  return request({
    url: `/report/unionoverallanaly/subjectfrequencydistributionnormaltestpic`,
    method: "get",
    params: params,
  });
}
export function studentlisttotal(params) {
  // 总体分析-总分-成绩榜
  return request({
    url: `/report/unionoverallanaly/studentlisttotal`,
    method: "post",
    data: params,
  });
}
export function studentlisttotaldetails(params) {
  // 总体分析-总分-详细成绩榜
  return request({
    url: `/report/unionoverallanaly/studentlisttotaldetails`,
    method: "post",
    data: params,
  });
}
export function studentlistsubject(params) {
  // 总体分析-单科-成绩榜
  return request({
    url: `/report/unionoverallanaly/studentlistsubject`,
    method: "post",
    data: params,
  });
}
export function studentlistsubjectdetails(params) {
  // 总体分析-单科-详细成绩榜
  return request({
    url: `/report/unionoverallanaly/studentlistsubjectdetails`,
    method: "post",
    data: params,
  });
}
export function totalindicatorcompare(params) {
  // 各校成绩对比-总分各项指标对比-总分各项指标对比
  return request({
    url: `/report/unionschoolcontrast/totalindicatorcompare`,
    method: "get",
    params: params,
  });
}
export function totalindicatorcomparepic(params) {
  // 各校成绩对比-总分各项指标对比-总分多维指标对比图
  return request({
    url: `/report/unionschoolcontrast/totalindicatorcomparepic`,
    method: "get",
    params: params,
  });
}
export function subjectindicatorcompare(params) {
  // 各校成绩对比-单科各项指标对比-单科各项指标对比
  return request({
    url: `/report/unionschoolcontrast/subjectindicatorcompare`,
    method: "get",
    params: params,
  });
}
export function subjectindicatorcomparepic(params) {
  // 各校成绩对比-单科各项指标对比-单科多维指标对比图
  return request({
    url: `/report/unionschoolcontrast/subjectindicatorcomparepic`,
    method: "get",
    params: params,
  });
}
export function indicatorcomparesummary(params) {
  // 各校成绩对比-各项指标对比汇总-对比汇总
  return request({
    url: `/report/unionschoolcontrast/indicatorcomparesummary`,
    method: "get",
    params: params,
  });
}
export function indicatorcompareaveragescore(params) {
  // 各校成绩对比-各项指标对比汇总-平均分
  return request({
    url: `/report/unionschoolcontrast/indicatorcompareaveragescore`,
    method: "get",
    params: params,
  });
}
export function indicatorcomparescorerate(params) {
  // 各校成绩对比-各项指标对比汇总-得分率
  return request({
    url: `/report/unionschoolcontrast/indicatorcomparescorerate`,
    method: "get",
    params: params,
  });
}
export function indicatorcomparestandarddeviation(params) {
  // 各校成绩对比-各项指标对比汇总-标准差
  return request({
    url: `/report/unionschoolcontrast/indicatorcomparestandarddeviation`,
    method: "get",
    params: params,
  });
}
export function indicatorcomparemedian(params) {
  // 各校成绩对比-各项指标对比汇总-中位数
  return request({
    url: `/report/unionschoolcontrast/indicatorcomparemedian`,
    method: "get",
    params: params,
  });
}
export function indicatorcomparehighestscore(params) {
  // 各校成绩对比-各项指标对比汇总-最高分
  return request({
    url: `/report/unionschoolcontrast/indicatorcomparehighestscore`,
    method: "get",
    params: params,
  });
}
export function indicatorcompareratio(params) {
  // 各校成绩对比-各项指标对比汇总-比率
  return request({
    url: `/report/unionschoolcontrast/indicatorcompareratio`,
    method: "get",
    params: params,
  });
}
export function classindicatorcompare(params) {
  // 各校成绩对比-班级各项指标对比
  return request({
    url: `/report/unionschoolcontrast/classindicatorcompare`,
    method: "get",
    params: params,
  });
}
export function totalscoresegmentcompare(params) {
  // 各校成绩对比-总分分数段对比-总分分数段对比
  return request({
    url: `/report/unionschoolcontrast/totalscoresegmentcompare`,
    method: "get",
    params: params,
  });
}
export function totalscoresegmentcomparedetails(params) {
  // 各校成绩对比-总分分数段对比-总分详细分数段对比
  return request({
    url: `/report/unionschoolcontrast/totalscoresegmentcomparedetails`,
    method: "get",
    params: params,
  });
}
export function subjectscoresegmentcompare(params) {
  // 各校成绩对比-单科分数段对比-单科分数段对比
  return request({
    url: `/report/unionschoolcontrast/subjectscoresegmentcompare`,
    method: "get",
    params: params,
  });
}
export function subjectscoresegmentcomparedetails(params) {
  // 各校成绩对比-单科分数段对比-单科详细分数段对比
  return request({
    url: `/report/unionschoolcontrast/subjectscoresegmentcomparedetails`,
    method: "get",
    params: params,
  });
}
export function totaltopsectioncompare(params) {
  // 各校成绩对比-总分前N名对比-前N名对比
  return request({
    url: `/report/unionschoolcontrast/totaltopsectioncompare`,
    method: "get",
    params: params,
  });
}
export function totaltopsectioncomparepic(params) {
  // 各校成绩对比-总分前N名对比-前N名对比图
  return request({
    url: `/report/unionschoolcontrast/totaltopsectioncomparepic`,
    method: "get",
    params: params,
  });
}
export function subjecttopsectioncompare(params) {
  // 各校成绩对比-单科前N名对比-前N名对比
  return request({
    url: `/report/unionschoolcontrast/subjecttopsectioncompare`,
    method: "get",
    params: params,
  });
}
export function subjecttopsectioncomparepic(params) {
  // 各校成绩对比-单科前N名对比-前N名对比图
  return request({
    url: `/report/unionschoolcontrast/subjecttopsectioncomparepic`,
    method: "get",
    params: params,
  });
}
export function subjectqualitycompare(params) {
  // 各校成绩对比-学科质量对比分析
  return request({
    url: `/report/unionschoolcontrast/subjectqualitycompare`,
    method: "get",
    params: params,
  });
}
export function subjectoverallevaluation(params) {
  // 各校成绩对比-学科总体评价
  return request({
    url: `/report/unionschoolcontrast/subjectoverallevaluation`,
    method: "get",
    params: params,
  });
}

export function totaltopsectioncomparesingletopsection(params) {
  // 各校成绩对比-总分前N名对比-前N名对比-单个前N名学生数据
  return request({
    url: `/report/unionschoolcontrast/totaltopsectioncomparesingletopsection`,
    method: "get",
    params: params,
  });
}
export function subjecttopsectioncomparesingletopsection(params) {
  // 各校成绩对比-单科前N名对比-前N名对比-单个前N名学生数据
  return request({
    url: `/report/unionschoolcontrast/subjecttopsectioncomparesingletopsection`,
    method: "get",
    params: params,
  });
}
export function questionanalysis(params) {
  // 试题分析-试题数据分析
  return request({
    url: `/report/unionquestionanaly/questionanalysis`,
    method: "get",
    params: params,
  });
}
export function choicequestionanalysis(params) {
  // 试题分析-试题数据分析
  return request({
    url: `/report/unionquestionanaly/choicequestionanalysis`,
    method: "get",
    params: params,
  });
}
export function nonchoicequestionanalysis(params) {
  // 试题分析-非选择题数据分析
  return request({
    url: `/report/unionquestionanaly/nonchoicequestionanalysis`,
    method: "get",
    params: params,
  });
}
export function choicequestionansweranalysis(params) {
  // 试题分析-选做题在作答分析
  return request({
    url: `/report/unionquestionanaly/choicequestionansweranalysis`,
    method: "get",
    params: params,
  });
}
export function schoolindicatorcompareaveragescore(params) {
  // 试题分析-常用指标各校对比-平均分
  return request({
    url: `/report/unionquestionanaly/schoolindicatorcompareaveragescore`,
    method: "get",
    params: params,
  });
}
export function schoolindicatorcomparescorerate(params) {
  // 试题分析-常用指标各校对比-得分率
  return request({
    url: `/report/unionquestionanaly/schoolindicatorcomparescorerate`,
    method: "get",
    params: params,
  });
}
export function schoolindicatorcomparecountright(params) {
  // 试题分析-常用指标各校对比-满分人数
  return request({
    url: `/report/unionquestionanaly/schoolindicatorcomparecountright`,
    method: "get",
    params: params,
  });
}
export function schoolindicatorcomparecountanswer(params) {
  // 试题分析-常用指标各校对比-答题人数
  return request({
    url: `/report/unionquestionanaly/schoolindicatorcomparecountanswer`,
    method: "get",
    params: params,
  });
}
export function schoolindicatorcomparechoicerate(params) {
  // 试题分析-常用指标各校对比-选题率
  return request({
    url: `/report/unionquestionanaly/schoolindicatorcomparechoicerate`,
    method: "get",
    params: params,
  });
}
export function classindicatorcompareaveragescore(params) {
  // 试题分析-常用指标班级对比-平均分
  return request({
    url: `/report/unionquestionanaly/classindicatorcompareaveragescore`,
    method: "get",
    params: params,
  });
}
export function classindicatorcomparescorerate(params) {
  // 试题分析-常用指标班级对比-得分率
  return request({
    url: `/report/unionquestionanaly/classindicatorcomparescorerate`,
    method: "get",
    params: params,
  });
}
export function classindicatorcomparecountright(params) {
  // 试题分析-常用指标班级对比-满分人数
  return request({
    url: `/report/unionquestionanaly/classindicatorcomparecountright`,
    method: "get",
    params: params,
  });
}
export function classindicatorcomparecountanswer(params) {
  // 试题分析-常用指标班级对比-答题人数
  return request({
    url: `/report/unionquestionanaly/classindicatorcomparecountanswer`,
    method: "get",
    params: params,
  });
}
export function classindicatorcomparechoicerate(params) {
  // 试题分析-常用指标班级对比-选题率
  return request({
    url: `/report/unionquestionanaly/classindicatorcomparechoicerate`,
    method: "get",
    params: params,
  });
}
export function questionscoredistribution(params) {
  // 试题分析-试题得分详细分布
  return request({
    url: `/report/unionquestionanaly/questionscoredistribution`,
    method: "get",
    params: params,
  });
}
export function paperreliability(params) {
  // 试卷命题分析-试卷信度统计
  return request({
    url: `/report/unionpaperanaly/paperreliability`,
    method: "get",
    params: params,
  });
}
export function questionsummaryanalysis(params) {
  // 试卷命题分析-试卷总汇分析
  return request({
    url: `/report/unionpaperanaly/questionsummaryanalysis`,
    method: "get",
    params: params,
  });
}
export function difficultydistribution(params) {
  // 试卷命题分析-试卷汇总分析-难度分布
  return request({
    url: `/report/unionpaperanaly/difficultydistribution`,
    method: "get",
    params: params,
  });
}
export function discriminationdistribution(params) {
  // 试卷命题分析-试卷汇总分析-区分度分布
  return request({
    url: `/report/unionpaperanaly/discriminationdistribution`,
    method: "get",
    params: params,
  });
}
export function difficultyleveldistribution(params) {
  // 试卷命题分析-试卷汇总分析-难度分布
  return request({
    url: `/report/unionpaperanaly/difficultyleveldistribution`,
    method: "get",
    params: params,
  });
}
export function discriminationleveldistribution(params) {
  // 试卷命题分析-区分度等级分布
  return request({
    url: `/report/unionpaperanaly/discriminationleveldistribution`,
    method: "get",
    params: params,
  });
}
export function typicalpaper(params) {
  // 特征试卷-典型试卷
  return request({
    url: `/report/unionfeaturepaper/typicalpaper`,
    method: "get",
    params: params,
  });
}
export function subjectquestionlist(params) {
  // 获取科目试题列表
  return request({
    url: `/report/unionfeaturepaper/subjectquestionlist`,
    method: "get",
    params: params,
  });
}
export function highscorequestion(params) {
  // 特征试卷-高分小题
  return request({
    url: `/report/unionfeaturepaper/highscorequestion`,
    method: "post",
    data: params,
  });
}
export function onlineoverall(params) {
  // 上线情况-总体情况
  return request({
    url: `/report/uniononlineanaly/onlineoverall`,
    method: "get",
    params: params,
  });
}
export function schooltotalonline(params) {
  // 上线情况-各校总分上线情况
  return request({
    url: `/report/uniononlineanaly/schooltotalonline`,
    method: "get",
    params: params,
  });
}
export function schoolsubjectonline(params) {
  // 上线情况-各校单科上线情况
  return request({
    url: `/report/uniononlineanaly/schoolsubjectonline`,
    method: "get",
    params: params,
  });
}
export function schoolallsubjectonline(params) {
  // 上线情况-各校各学科上线情况
  return request({
    url: `/report/uniononlineanaly/schoolallsubjectonline`,
    method: "get",
    params: params,
  });
}
export function gradescorelist(params) {
  // 学校分析-年级成绩榜
  return request({
    url: `/report/unionschoolanaly/gradescorelist`,
    method: "post",
    data: params,
  });
}
export function getstatonlinesetting(params) {
  // 获取联考统计上线设置
  return request({
    url: `/report/stat/getstatonlinesetting`,
    method: "get",
    params: params,
  });
}
export function setstatonlinesetting(params) {
  // 保存联考统计上线设置
  return request({
    url: `/report/stat/generatestatuniononline`,
    method: "post",
    data: params,
  });
}
export function importExamToSchool(params) {
  // 导入到学校
  return request({
    url: `/report/stat/importtoschool`,
    method: "post",
    data: params,
  });
}
export function subjectscoreratedistributionpic(params) {
  // 试卷命题分析-学科得分率分布
  return request({
    url: `/report/unionpaperanaly/subjectscoreratedistributionpic`,
    method: "get",
    params: params,
  });
}
export function scorerateindexdistributionpic(params) {
  // 试卷命题分析-难度指数分布图
  return request({
    url: `/report/unionpaperanaly/scorerateindexdistributionpic`,
    method: "get",
    params: params,
  });
}
