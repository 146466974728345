var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"container"},[_c('div',{staticClass:"operate"},[_c('div',{staticClass:"title-content"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$route.query.name)+"-生成上线情况")])])]),(_vm.form.onlineType == 2)?_c('div',{staticClass:"subjectEpType"},[_c('a-icon',{attrs:{"type":"exclamation-circle","theme":"filled"}}),_vm._v("请选择单科有效分计算方式："),_c('a-radio-group',{model:{value:(_vm.form.subjectEpType),callback:function ($$v) {_vm.$set(_vm.form, "subjectEpType", $$v)},expression:"form.subjectEpType"}},[_c('a-radio',{attrs:{"value":1}},[_vm._v("按单科成绩榜取上线人数")]),_c('a-radio',{attrs:{"value":2}},[_vm._v("单科榜计算新总分榜，取总分有效分所在行")])],1)],1):_vm._e(),_c('div',{staticClass:"radios"},[_c('a-radio-group',{on:{"change":_vm.onlineTypeChange},model:{value:(_vm.form.onlineType),callback:function ($$v) {_vm.$set(_vm.form, "onlineType", $$v)},expression:"form.onlineType"}},[_c('a-radio',{attrs:{"value":1}},[_vm._v(" 按比例 ")]),_c('a-radio',{attrs:{"value":2}},[_vm._v(" 按人数 ")]),_c('a-radio',{attrs:{"value":3}},[_vm._v(" 按有效分 ")])],1)],1),_c('a-form-model',{ref:"form",attrs:{"model":_vm.form}},[(_vm.form.onlineType === 1)?_vm._l((_vm.form.statOnlineSettings),function(item,index){return _c('a-form-model-item',{key:'onlineType1' + index,attrs:{"prop":`statOnlineSettings[${index}].value`,"rules":{
          required: true,
          validator: (rule, value, callback) =>
            _vm.validType1(rule, value, callback, index),
          trigger: 'blur',
        }}},[_c('a-input',{staticStyle:{"width":"120px"},attrs:{"placeholder":"上线名称"},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}}),_c('span',{staticClass:"space"},[_vm._v(" 设上线比例为")]),_c('a-input',{staticStyle:{"width":"120px"},on:{"blur":function($event){return _vm.$refs.form.validate()}},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}),_c('span',{staticClass:"space"},[_vm._v("%")]),_c('span',{staticClass:"delete",on:{"click":function($event){return _vm.delItem(index)}}},[_c('a-icon',{staticStyle:{},attrs:{"type":"delete"}}),_vm._v("删除")],1)],1)}):_vm._e(),(_vm.form.onlineType === 2)?_vm._l((_vm.form.statOnlineSettings),function(item,index){return _c('a-form-model-item',{key:'onlineType2' + index,attrs:{"prop":'statOnlineSettings.' + index + '.value',"rules":{
          validator: (rule, value, callback) =>
            _vm.validType2(rule, value, callback, index),
          trigger: 'blur',
        }}},[_c('a-input',{staticStyle:{"width":"120px"},attrs:{"placeholder":"上线名称"},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}}),_c('span',{staticClass:"space"},[_vm._v(" ： 上线人数")]),_c('a-input',{staticStyle:{"width":"120px"},on:{"blur":function($event){return _vm.$refs.form.validate()}},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}),_c('span',{staticClass:"delete",on:{"click":function($event){return _vm.delItem(index)}}},[_c('a-icon',{staticStyle:{},attrs:{"type":"delete"}}),_vm._v("删除")],1)],1)}):_vm._e(),(_vm.form.onlineType === 3)?_vm._l((_vm.form.statOnlineSettings),function(item,index){return _c('a-form-model-item',{key:'onlineType3' + index,attrs:{"prop":'statOnlineSettings.' + index + '.value',"rules":{
          validator: (rule, value, callback) =>
            _vm.validType3(rule, value, callback, index),
          trigger: 'blur',
        }}},[_c('a-input',{staticStyle:{"width":"120px"},attrs:{"placeholder":"上线名称"},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}}),_c('span',{staticClass:"space"},[_vm._v("，有效分：")]),_c('a-input',{staticStyle:{"width":"320px"},attrs:{"disabled":""},on:{"blur":function($event){return _vm.$refs.form.validate()}},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}),_c('a-button',{staticStyle:{"margin-left":"8px"},attrs:{"ghost":"","type":"primary"},on:{"click":function($event){return _vm.setting(item, index)}}},[_vm._v("设置")]),_c('span',{staticClass:"delete",on:{"click":function($event){return _vm.delItem(index)}}},[_c('a-icon',{staticStyle:{},attrs:{"type":"delete"}}),_vm._v("删除")],1)],1)}):_vm._e()],2),_c('div',{staticClass:"add",on:{"click":_vm.add}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v("增加")],1),_c('a-space',[_c('a-button',{attrs:{"type":"primary","loading":_vm.crateloading},on:{"click":_vm.submit}},[_vm._v("生成")]),_c('a-button',{attrs:{"type":"primary","loading":_vm.exportLoading},on:{"click":_vm.exportFile}},[_vm._v("导出")])],1),_c('a-modal',{attrs:{"title":"有效分设置","width":"625px"},on:{"ok":_vm.handleOk,"cancle":_vm.handleCancle},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('a-form-model',{ref:"modalForm",attrs:{"model":_vm.modalForm}},[_c('div',{staticClass:"subjectList"},_vm._l((_vm.modalForm.scoreList),function(item,index){return _c('a-form-model-item',{key:'scoreList' + index,attrs:{"prop":'scoreList.' + index + '.value',"rules":{
            trigger: 'blur',
            validator: (rule, value, callback) =>
              _vm.checkValue(rule, value, callback, index),
          }}},[_c('div',{staticClass:"subject"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.name)+"：")]),_c('div',{staticClass:"input"},[_c('a-input',{on:{"blur":function($event){return _vm.$refs.modalForm.validate()}},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", _vm._n($$v))},expression:"item.value"}})],1)])])}),1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }