<template>
  <div v-loading="loading" class="container">
    <div class="operate">
      <div class="title-content">
        <div class="title">{{ $route.query.name }}-生成上线情况</div>
      </div>
    </div>
    <div v-if="form.onlineType == 2" class="subjectEpType">
      <a-icon
        type="exclamation-circle"
        theme="filled"
      />请选择单科有效分计算方式：<a-radio-group v-model="form.subjectEpType">
        <a-radio :value="1">按单科成绩榜取上线人数</a-radio>
        <a-radio :value="2">单科榜计算新总分榜，取总分有效分所在行</a-radio>
      </a-radio-group>
    </div>
    <div class="radios">
      <a-radio-group v-model="form.onlineType" @change="onlineTypeChange">
        <a-radio :value="1"> 按比例 </a-radio>
        <a-radio :value="2"> 按人数 </a-radio>
        <a-radio :value="3"> 按有效分 </a-radio>
      </a-radio-group>
    </div>
    <a-form-model ref="form" :model="form">
      <template v-if="form.onlineType === 1">
        <a-form-model-item
          v-for="(item, index) in form.statOnlineSettings"
          :key="'onlineType1' + index"
          :prop="`statOnlineSettings[${index}].value`"
          :rules="{
            required: true,
            validator: (rule, value, callback) =>
              validType1(rule, value, callback, index),
            trigger: 'blur',
          }"
        >
          <a-input
            v-model="item.name"
            placeholder="上线名称"
            style="width: 120px"
          ></a-input>
          <span class="space"> 设上线比例为</span>
          <a-input
            v-model="item.value"
            style="width: 120px"
            @blur="$refs.form.validate()"
          ></a-input>
          <span class="space">%</span>
          <span class="delete" @click="delItem(index)"
            ><a-icon type="delete" style="color" />删除</span
          >
        </a-form-model-item>
      </template>
      <template v-if="form.onlineType === 2">
        <a-form-model-item
          v-for="(item, index) in form.statOnlineSettings"
          :key="'onlineType2' + index"
          :prop="'statOnlineSettings.' + index + '.value'"
          :rules="{
            validator: (rule, value, callback) =>
              validType2(rule, value, callback, index),
            trigger: 'blur',
          }"
        >
          <a-input
            v-model="item.name"
            placeholder="上线名称"
            style="width: 120px"
          ></a-input>
          <span class="space"> ： 上线人数</span>
          <a-input
            v-model="item.value"
            style="width: 120px"
            @blur="$refs.form.validate()"
          ></a-input>
          <span class="delete" @click="delItem(index)"
            ><a-icon type="delete" style="color" />删除</span
          >
        </a-form-model-item>
      </template>
      <template v-if="form.onlineType === 3">
        <a-form-model-item
          v-for="(item, index) in form.statOnlineSettings"
          :key="'onlineType3' + index"
          :prop="'statOnlineSettings.' + index + '.value'"
          :rules="{
            validator: (rule, value, callback) =>
              validType3(rule, value, callback, index),
            trigger: 'blur',
          }"
        >
          <a-input
            v-model="item.name"
            placeholder="上线名称"
            style="width: 120px"
          ></a-input>
          <span class="space">，有效分：</span>
          <a-input
            v-model="item.value"
            disabled
            style="width: 320px"
            @blur="$refs.form.validate()"
          ></a-input>
          <a-button
            style="margin-left: 8px"
            ghost
            type="primary"
            @click="setting(item, index)"
            >设置</a-button
          >
          <span class="delete" @click="delItem(index)"
            ><a-icon type="delete" style="color" />删除</span
          >
        </a-form-model-item>
      </template>
    </a-form-model>
    <div class="add" @click="add"><a-icon type="plus" />增加</div>
    <a-space>
      <a-button type="primary" :loading="crateloading" @click="submit"
        >生成</a-button
      >
      <a-button type="primary" :loading="exportLoading" @click="exportFile"
        >导出</a-button
      >
    </a-space>
    <a-modal
      v-model="visible"
      title="有效分设置"
      width="625px"
      @ok="handleOk"
      @cancle="handleCancle"
    >
      <a-form-model ref="modalForm" :model="modalForm">
        <div class="subjectList">
          <a-form-model-item
            v-for="(item, index) in modalForm.scoreList"
            :key="'scoreList' + index"
            :prop="'scoreList.' + index + '.value'"
            :rules="{
              trigger: 'blur',
              validator: (rule, value, callback) =>
                checkValue(rule, value, callback, index),
            }"
          >
            <div class="subject">
              <div class="name">{{ item.name }}：</div>
              <div class="input">
                <a-input
                  v-model.number="item.value"
                  @blur="$refs.modalForm.validate()"
                ></a-input>
              </div>
            </div>
          </a-form-model-item>
        </div>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import {
  getstatonlinesetting,
  setstatonlinesetting,
} from "@/core/api/academic/unionSheet";
import { getstatinfo } from "@/core/api/academic/common";
import { onlineSettingExport } from "@/core/api/academic/unionSheetExport";

export default {
  name: "",
  data() {
    return {
      loading: false,
      form: {
        statOnlineSettings: [{}],
        onlineType: 1,
        subjectEpType: 2,
      },
      crateloading: false,
      statOnlineSettings: [],
      subIndex: 0,
      visible: false,
      subjectList: [],
      scoreList: [],
      formStat: {},
      exportLoading: false,
      modalForm: {
        scoreList: [],
      },
      timer: null,
      statusOnline: null, // 统计上线状态 0-未统计 1-已统计 2-处理中 3-统计失败
    };
  },

  mounted() {
    this.getStatInfoData();
  },
  methods: {
    clearTimer() {
      if (this.statusOnline == 2) {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
      }
    },
    async getStatInfoData() {
      try {
        const res = await getstatinfo({ id: this.$route.query.id });
        this.statusOnline = res.data.data.stat.statusOnline;
        if (this.statusOnline == 2) {
          this.loading = true;
          this.timer = setTimeout(() => {
            this.getStatInfoData();
          }, 5000);
        } else {
          this.loading = false;
          this.clearTimer();
          this.getData();
        }
      } catch {
        this.loading = false;
        this.clearTimer();
      }
    },
    async exportFile() {
      this.exportLoading = true;
      try {
        const res = await onlineSettingExport({ id: this.$route.query.id });
        const { href } = this.$router.resolve({
          path: "/downFile",
          query: {
            url: res.data.data.url,
            name: res.data.data.name,
            type: 1,
          },
        });
        let timer = setTimeout(() => {
          window.open(href, "_blank");
          clearTimeout(timer);
        }, 0);
        this.exportLoading = false;
      } catch {
        this.exportLoading = false;
      }
    },
    checkValue(rule, value, callback, index) {
      let subject = this.modalForm.scoreList[index];
      if (!value) {
        return callback(new Error("请输入学科上线分数"));
      } else if (typeof value !== "number" || isNaN(value)) {
        return callback(new Error("分数只能是数字"));
      } else if (
        subject.name == "总分" &&
        subject.value > this.formStat.score
      ) {
        return callback(new Error("总分上线分数不能大于总分"));
      } else if (
        subject.name != "总分" &&
        value > this.subjectList[index].score
      ) {
        return callback(new Error(subject.name + "上线不能大于科目总分"));
      } else {
        return callback();
      }
    },
    async getData() {
      const res = await getstatonlinesetting({ id: this.$route.query.id });
      this.form.statOnlineSettings = res.data.data.statOnlineSettings;
      this.statOnlineSettings[res.data.data.onlineType - 1] =
        res.data.data.statOnlineSettings;
      this.form.onlineType = res.data.data.onlineType;
      this.subjectList = [
        { name: "总分", isTotal: 0, subjectId: 0, score: 0 },
        ...res.data.data.statSubjects,
      ];
      this.form.subjectEpType = res.data.data.subjectEpType;
      this.formStat = res.data.data.stat;
    },
    handleCancle() {
      this.visible = false;
    },
    handleOk() {
      this.$refs.modalForm.validate((valid) => {
        if (valid) {
          this.form.statOnlineSettings[this.subIndex].value =
            this.modalForm.scoreList.reduce((prev, item) => {
              prev = prev + item.name + ":" + item.value + ";";
              return prev;
            }, "");
          this.form.statOnlineSettings[this.subIndex].statOnlineEpSettings = [
            ...this.modalForm.scoreList.map((item) => {
              let subjectId = null;
              if (item.name === "总分") {
                subjectId = 0;
              } else {
                let subject = this.subjectList.find(
                  (it) => it.name == item.name
                );
                subjectId = subject.subjectId;
              }
              return {
                ...item,
                name: item.name,
                subjectId: subjectId,
                value: Number(item.value),
              };
            }),
          ];
          this.visible = false;
          this.$refs.form.validate();
          this.$forceUpdate();
        }
      });
    },
    setting(item, index) {
      this.subIndex = index;
      this.visible = true;
      let arr = [];
      if (item.value) {
        if (item.value.indexOf(";") != -1) {
          arr = item.value.split(";");
        } else if (item.value.indexOf("；") != -1) {
          arr = item.value.split("；");
        } else {
          arr = [];
        }
      }
      if (arr.length) {
        let data = arr.map((it) => {
          return {
            name: it.match(/(\S*):/) ? it.match(/(\S*):/)[1] : "",
            value: it.match(/(\S*):/) ? Number(it.match(/:(\S*)/)[1]) : "",
          };
        });
        this.modalForm.scoreList = data.filter(
          (item) => item.name && item.value !== null
        );
      } else {
        this.modalForm.scoreList = this.subjectList.map((item) => ({
          name: item.name,
          value: null,
          subjectId: item.subjectId,
          isTotal: item.isTotal,
          score: item.score,
        }));
      }
    },
    onlineTypeChange() {
      if (this.onlineType == 2) {
        this.$delete(this.form, "subjectEpType");
      }
      if (this.statOnlineSettings[this.form.onlineType - 1]) {
        this.form.statOnlineSettings = [
          ...this.statOnlineSettings[this.form.onlineType - 1],
        ];
      } else {
        this.statOnlineSettings[this.form.onlineType - 1] = [
          {
            name: "第一批次",
            value: "",
          },
          {
            name: "第二批次",
            value: "",
          },
          {
            name: "第三批次",
            value: "",
          },
        ];
        this.form.statOnlineSettings = [
          {
            name: "第一批次",
            value: "",
          },
          {
            name: "第二批次",
            value: "",
          },
          {
            name: "第三批次",
            value: "",
          },
        ];
      }
    },
    add() {
      this.form.statOnlineSettings.push({ value: null, name: null });
    },
    submit() {
      this.$refs.form.validate(async (valid) => {
        if (this.onlineType == 2 && !this.form.subjectEpType) {
          this.$message({
            showClose: true,
            message: "请选择单科有效分计算方式",
            type: "warning",
          });
          return false;
        }
        if (valid) {
          try {
            this.crateloading = true;
            let res = await setstatonlinesetting({
              onlineType: this.form.onlineType,
              subjectEpType: this.form.subjectEpType,
              statOnlineSettings: this.form.statOnlineSettings,
              statId: this.$route.query.id,
            });
            this.crateloading = false;
            this.$message({
              showClose: true,
              message: res.data.data,
              type: "warning",
            });
            this.getStatInfoData();
          } catch {
            this.crateloading = false;
          }
        }
      });
    },
    delItem(index) {
      this.form.statOnlineSettings.splice(index, 1);
    },
    validType1(rule, value, callback, index) {
      let val = this.form.statOnlineSettings[index].value;
      let name = this.form.statOnlineSettings[index].name;
      let reg = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^[0-9]\.[0-9]([0-9])?$)/;

      if (!name) {
        return callback(new Error("对不起，请输入比率名称"));
      } else if (!val || !reg.test(val) || val < 0) {
        return callback(new Error("请输入0-100且不超过2位小数的数值"));
      } else if (val > 100) {
        return callback(new Error("比率不能超过100%"));
      } else {
        callback();
      }
    },
    validType2(rule, value, callback, index) {
      let val = this.form.statOnlineSettings[index].value;
      let name = this.form.statOnlineSettings[index].name;
      let reg = /^[-+]?\d*$/;

      if (!name) {
        return callback(new Error("对不起，请输入比率名称"));
      } else if (!val || !reg.test(val) || val < 0) {
        return callback(new Error("请输入正整数"));
      } else {
        callback();
      }
    },
    validType3(rule, value, callback, index) {
      let data = this.form.statOnlineSettings[index];
      if (data) {
        if (!data.name) {
          callback(new Error("请输入有效分名称"));
        } else if (!data.value) {
          callback(new Error("请设置有效分区间"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  .operate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;

    .title-content {
      display: flex;
      align-items: center;

      .title {
        font-size: 14px;
        color: #0a1119;
        font-weight: 600;
        margin-right: 8px;
      }
    }
  }

  .radios {
    margin-bottom: 18px;
  }

  .delete {
    color: #f56c6b;
    font-size: 14px;
    cursor: pointer;
    margin-left: 17px;
  }

  .space {
    margin: 0 8px;
    color: #0a1119;
    font-size: 14px;
  }

  ::v-deep .ant-form-item-control {
    width: fit-content;
  }

  ::v-deep .ant-form-item {
    margin-bottom: 18px;
  }

  ::v-deep .has-error {
    position: relative;
  }

  ::v-deep .ant-form-explain {
    position: absolute;
    left: 105%;
    top: 56%;
    transform: translateY(-50%);
    white-space: nowrap;
    color: #f56c6b;
    font-size: 14px;
  }

  .add {
    width: 331px;
    text-align: center;
    line-height: 32px;
    border-radius: 4px;
    border: 1px dashed #d5d6db;
    height: 32px;
    margin: 18px 0;
  }
}

.subjectList {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  .subject {
    margin-bottom: 18px;
    margin-right: 18px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 4px;
    border: 1px dashed #dee0e7;

    .name {
      color: #0a1119;
      font-size: 14px;
      height: 56px;
      text-align: center;
      width: 132px;
      border-right: 1px dashed #dee0e7;
      line-height: 56px;
    }

    .input {
      box-sizing: border-box;
      width: 132px;
      height: 56px;
      padding: 12px 16px;
    }
  }
}

.subjectEpType {
  margin-bottom: 12px;
  height: 36px;
  background: #f3f8ff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 12px;

  i {
    color: #2474ed;
    margin-right: 8px;
  }
}
</style>
